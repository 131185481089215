import {Button, Row} from 'reactstrap';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import {useQuery} from "react-query";
import CompanyService from "../../../services/company.service";
import Loader from "../../../layouts/loader/Loader";
import ComponentCard from "../../../components/ComponentCard";
import PrettyPrintJson from "../../../components/PrettyPrintJson";
import {Company} from "../../../model/Company";

const OptionsItem = (props: any) => {
    const { edit } = props;
    const { id } = useParams()

    const { isLoading, isError, data, error } = useQuery<Company, Error>(['company', id], () => CompanyService.findById(id))

    const navigate = useNavigate();

    if (isLoading) {
        return <Loader />
    }

    if (isError || !data) {
        return <h2>Error...</h2>
    }

    return (
        <>
            <ComponentCard
                title={`${edit ? 'Edit' : ''} Company ${data.name}`}
                extra={
                    <Button className="btn btn-success ms-auto float-end" size="sm" onClick={() => navigate(-1)}>
                        Go Back
                    </Button>
                }
            >
                <Row>
                    <PrettyPrintJson {...data} />
                </Row>
            </ComponentCard>
        </>
    );
};

OptionsItem.propTypes = {
    edit: PropTypes.bool,
};

export default OptionsItem;

