import {configureStore} from '@reduxjs/toolkit';

import ConfirmdialogReducer from "./confirmdialog/ConfirmdialogSlice";
import AuthReducer from './auth/AuthSlice';
import MessageReducer from './message/MessageSlice';

const loadState = () =>{
  try {
    const localUser = localStorage.getItem("user");
    if (localUser) {
      const user = JSON.parse(localUser || '{}');

      return {
        auth: {
          isLoggedIn: true,
          isFetchingInitState: true,
          isMobileSidebar: false,
          user: user.user,
          dark: user.user.dark,
          scanState: user.user.scanState,
        }
      };
    }
  } catch (e) {
    // ignore
  }
  return undefined;
}

export const store = configureStore({
  reducer: {
    confirmdialog: ConfirmdialogReducer,
    auth: AuthReducer,
    message: MessageReducer,
  },
  preloadedState: loadState(),
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;
export default store;
