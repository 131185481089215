import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, ModalBody, ModalFooter, Spinner,} from 'reactstrap';
import {useQueryClient} from "react-query";
import 'react-quill/dist/quill.snow.css';
import {Formik} from "formik";
import * as Yup from "yup";
import {addArticle} from "../../../actions/article.actions";
import PTextInput from "../../../components/form/PTextInput";
import PRoleDropdown from "../../../components/form/PRoleDropdown";
import PQuill from "../../../components/form/PQuill";

const ArticleAdd = ({ click }: any) => {
    const queryClient = useQueryClient()

    return (
        <Formik
            initialValues={{
                title: '',
                description: '',
                roleNames: [],
                image: '',
                content: '',
            }}
            validationSchema={Yup.object().shape({
                title: Yup.string().required('Title is required'),
                description: Yup.string().required('Description is required'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                addArticle(values, queryClient)
                    .finally(() => {
                        setSubmitting(false)
                    })
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Form>
                    <ModalBody>
                        <PTextInput
                            label="Title"
                            name="title"
                            type="text"
                        />
                        <PTextInput
                            label="Description"
                            name="description"
                            type="text"
                        />
                        <PTextInput
                            label="Image URL (Optional)"
                            name="image"
                            type="text"
                        />
                        <PRoleDropdown
                            label="Minimum Role Required to View Article"
                            name="roleNames"
                        />
                        <PQuill
                            label="Content"
                            name="content"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <FormGroup>
                            <Button
                                onClick={submitForm}
                                className="w-200px"
                                color="primary"
                                disabled={isSubmitting}
                            >{isSubmitting ? <Spinner size="sm"/> : ''} Add Article
                            </Button>
                        </FormGroup>
                    </ModalFooter>
                </Form>
            )}
        </Formik>
    );
};

ArticleAdd.propTypes = {
    click: PropTypes.any,
};

export default ArticleAdd;
