import axios from "axios";
import BASE_URL from "./constants";
import {CompanyInviteToken} from "../model/Company";

const API_URL = `${BASE_URL}auth/`;

const login = (email: any, password: any) => {
    return axios
        .post(`${API_URL}signin`, { email, password })
        .then((response) => {
            if (response.data) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        });
}

const refreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    if (user && user.refreshToken) {
        return axios
            .post(`${API_URL}refreshtoken`, {refreshToken: user.refreshToken})
            .then((response) => {
                if (response.data) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    }
    return null;
}

const me = () => {
    return axios
        .get(`${API_URL}me`)
        .then((response) => {
            if (response.data) {
                const user = JSON.parse(localStorage.getItem("user") || '{}');
                localStorage.setItem("user", JSON.stringify({...user, user: response.data}));
            }
            return response.data;
        });
}


const logout = () => {
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    if (user && user.refreshToken) {
        return axios
            .post(`${API_URL}logout`, {refreshToken: user.refreshToken});
    }
    return Promise.reject();
}

const register = (companyName: string, firstName: string, lastName: string, email: string, password: string) => {
    return axios.post(`${API_URL}signup`, {
        companyName,
        firstName,
        lastName,
        email,
        password,
    });
}

const recover = (email: any) => {
    return axios
        .post(`${API_URL}recover`, { email })
        .then((response) => {
            return response.data;
        });
}


const getByRecoverToken = (token: any) => {
    return axios
        .get(`${API_URL}recovertoken/${token}`)
        .then((response) => {
            return response.data;
        });
}

const handleRecoverByToken = (token: string, password: string) => {
    return axios.post(`${API_URL}recovertoken`, {
        token,
        password,
    });
}

const getByInviteToken = (token: any) => {
    return axios
        .get(`${API_URL}invite/${token}`)
        .then((response) => {
            return response.data;
        });
}

const handleInviteToken = (handle: CompanyInviteToken) => {
    return axios.post(`${API_URL}invite`, {...handle});
}


const AuthService = {
    login,
    refreshToken,
    logout,
    register,
    me,
    recover,
    getByRecoverToken,
    handleRecoverByToken,
    getByInviteToken,
    handleInviteToken
}
export default AuthService;