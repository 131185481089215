import {useField} from "formik";
import {FormGroup, Label} from "reactstrap";
import React, {useEffect, useState} from "react";
import ReactQuill from "react-quill";

const PQuill = ({ label, ...props }: any) => {
    const [option, setOption] = useState<any>([]);
    const [field, meta, helpers] = useField(props.name);

    const { setValue } = helpers;

    useEffect(() => {
        if (field.value) {
            setOption(field.value);
        }
    }, [field.value]);

    return (
        <FormGroup>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <div
                style={{padding: '0'}}
                className={`form-control ${
                    meta.touched && meta.error ? ' is-invalid' : ''
                }`}>
                <ReactQuill theme="snow"
                            value={option}
                            onChange={(value: any) => {
                                setOption(value)
                                setValue(value)
                            }} />
            </div>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </FormGroup>
    );
};


export default PQuill;