import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Row} from 'reactstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import AuthLogo from "../../layouts/logo/AuthLogo";
import {ReactComponent as LeftBg} from '../../assets/images/bg/login-bgleft.svg';
import {ReactComponent as RightBg} from '../../assets/images/bg/login-bg-right.svg';
import {AppDispatch} from "../../store/Store";
import AuthService from "../../services/auth.service";
import Loader from "../../layouts/loader/Loader";
import ErrorMessage from "../../components/utility/ErrorMessage";
import {handleInvite, register} from "../../actions/auth.actions";
import PTextInput from "../../components/form/PTextInput";
import PCheckbox from "../../components/form/PCheckbox";
import {AddMessage, ClearMessage} from "../../store/message/MessageSlice";

const Page = ({children}: any) => {
    return (
        <div className="loginBox">
            <LeftBg className="position-absolute left bottom-0" />
            <RightBg className="position-absolute end-0 top" />
            <Container fluid className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col lg="12" className="loginContainer">
                        <AuthLogo />
                        <Card>
                            <CardBody className="p-4 m-1">
                                {children}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const Register = () => {
    const { token } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const [initValues, setInitValues] = useState<any>({
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        acceptTerms: false,
    });

    const [inviteData, setInviteData] = useState<any>();
    const [isLoading, setLoading] = useState(token !== undefined);

    useEffect(() => {
        if (token !== undefined) {
            AuthService.getByInviteToken(token)
                .then((response: any) => {
                    setInviteData(response);

                    const companyName = response.company.name;

                    setInitValues({
                        ...initValues,
                        companyName,
                        email: response.email
                    });
                    dispatch(ClearMessage())
                    dispatch(AddMessage({ message: `You have been invited by ${companyName}`, type: "success", displayLater: true }));
                })
                .catch(error => {
                    const message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    dispatch(ClearMessage())
                    dispatch(AddMessage({ message, type: "error", displayLater: true }));
                })
                .finally((  ) => {
                    setLoading(false)
                })
        }
    }, [token]);

    if (isLoading) {
        return <Loader />
    }

    return (<Page>
            <ErrorMessage />
            <h5 className="mb-0">Register</h5>
            <small className="pb-4 d-block">
                Already have an account? <Link to="/login">Login</Link>
            </small>

            <Formik
                initialValues={initValues}
                validationSchema={Yup.object().shape({
                    companyName: Yup.string().required('Company name is required'),
                    firstName: Yup.string().required('First name is required'),
                    lastName: Yup.string().required('Last name is required'),
                    email: Yup.string().email('Email is invalid').required('Email is required'),
                    password: Yup.string()
                        .min(6, 'Password must be at least 6 characters')
                        .required('Password is required'),
                    acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                    if (inviteData) {
                        dispatch(handleInvite(token as string, values.firstName, values.lastName, values.password))
                            .then(() => {
                                navigate('/');
                            })
                            .finally(() => {
                                setSubmitting(false)
                            });
                    } else {
                        dispatch(register(values.companyName, values.firstName, values.lastName, values.email, values.password))
                            .then(() => {
                                navigate('/');
                            })
                            .finally(() => {
                                setSubmitting(false)
                            });
                    }
                }}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <PTextInput
                            label="Company Name"
                            name="companyName"
                            type="text"
                            disabled={inviteData}
                        />
                        <Row>
                            <Col xs="12" md="6">
                                <PTextInput
                                    label="First Name"
                                    name="firstName"
                                    type="text"
                                />
                            </Col>
                            <Col xs="12" md="6">
                                <PTextInput
                                    label="Last Name"
                                    name="lastName"
                                    type="text"
                                />
                            </Col>
                        </Row>
                        <PTextInput
                            label="Email"
                            name="email"
                            type="email"
                            disabled={inviteData}
                        />
                        <PTextInput
                            label="Password"
                            name="password"
                            type="password"
                        />
                        <PCheckbox name="acceptTerms">
                            Accept <a rel="noreferrer" href="https://paperlessproduction.nl/algemene-voorwaarden/" target="_blank">Terms & Conditions</a>
                        </PCheckbox>
                        <FormGroup>
                            <Button disabled={isSubmitting} type="submit" color="primary" className="me-2">
                                Register
                            </Button>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </Page>
    );
};

export default Register;
