import React, {useState} from "react";
import * as Sentry from "@sentry/browser";
import {useSelector} from "react-redux";
import {ErrorBoundary} from 'react-error-boundary'
import {Button, Card, CardBody, CardTitle, Col, Container, Row} from "reactstrap";
import {useLocation} from "react-router-dom";
import {ReactComponent as LeftBg} from "../../assets/images/bg/login-bgleft.svg";
import {ReactComponent as RightBg} from "../../assets/images/bg/login-bg-right.svg";
import AuthLogo from "../../layouts/logo/AuthLogo";
import {RootState} from "../../store/Store";

const ErrorFallback = ({ children, ...props }: any) => {
    const { pathname } = useLocation();
    const [errorId, setErrorId] = useState<string>();
    const user = useSelector((state: RootState) => state.auth.user);

    const onClick = () => {
        Sentry.showReportDialog({ errorId, user: {email: user?.email, name: user?.fullName} })
    }

    const myErrorHandler = (error: Error, info: {componentStack: string}) => {
        console.error("Uncaught error:", error, info);
        Sentry.withScope((scope) => {
            setErrorId(Sentry.captureException(error));
        });
    }

    function ErrorFallbackLayout({error}: any) {
        return (
            <div className="loginBox">
                <LeftBg className="position-absolute left bottom-0" />
                <RightBg className="position-absolute end-0 top" />
                <Container fluid className="h-100">
                    <Row className="justify-content-center align-items-center h-100">
                        <Col lg="12" className="loginContainer">
                            <AuthLogo />
                            <Card>
                                <CardBody className="p-4 m-1 text-center">
                                    <div className="mt-3 mb-2">
                                        <i className="bi bi-exclamation-triangle-fill text-warning display-5" />
                                    </div>
                                    <CardTitle tag="h4">Something went wrong!</CardTitle>
                                    <h5 className="mb-0 text-muted font-medium">Something wrong going on this page.</h5>
                                    <h5 className="text-muted font-medium mb-4">Please Check back again.</h5>
                                    <Button color="danger" onClick={onClick} block>
                                        Report feedback
                                    </Button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    return (
        <ErrorBoundary
            key={pathname}
            FallbackComponent={ErrorFallbackLayout}
            onError={myErrorHandler}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorFallback;