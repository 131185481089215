import React from "react";
import MessageType from "../../store/message/MessageType";

const Message = (message: MessageType) => {
    if (message.type === "success") {
        return <div className="alert alert-success" role="alert">
            {message.message}
        </div>
    }

    if (message.type === "error") {
        return <div className="alert alert-danger" role="alert">
            {message.message}
        </div>
    }
    return null;
}

export default Message;