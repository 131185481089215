import Analytics from 'analytics'
// @ts-ignore
import mixpanelPlugin from '@analytics/mixpanel'


const ENV_CHECK: boolean = true; // process.env.NODE_ENV === 'production';

const analytics = Analytics({
    app: 'paperless-window',
    plugins: [
        mixpanelPlugin({
            token: '0aea83c7e03d777bfdd2c155709a9e67'
        })
    ],
    debug: process.env.NODE_ENV === 'development'
})

const actions = {
    identify: (userId: string, traits?: any): Promise<any> => {
        if (ENV_CHECK)
            return analytics.identify(userId, traits);
        return Promise.resolve();
    },
    track: (eventName: string, payload?: any, options?: any, callback?: (...params: any[]) => any): Promise<any> => {
        if (ENV_CHECK)
            return analytics.track(eventName, payload, options, callback);
        return Promise.resolve();
    },
    reset(): Promise<any> {
        return analytics.reset();
    }
};

export default actions;


/*
function jaja(): any {
    const actions = {
        identify: (id: string) => {
            console.log("JAJAJA", id);
            if (ENV_CHECK) mixpanel.identify(id);
        },
        alias: (id: string) => {
            if (ENV_CHECK) mixpanel.alias(id);
        },
        track: (name: string, props?: any) => {
            if (ENV_CHECK) mixpanel.track(name, props);
        },
        people: {
            set: (props: any) => {
                if (ENV_CHECK) mixpanel.people.set(props);
            },
        },
    };

    return {
        test: actions.identify,
    };
}

export default jaja;*/

/*
mixpanel.init('0aea83c7e03d777bfdd2c155709a9e67', {debug: true});


const actions = {
    identify: (id: string) => {
        if (ENV_CHECK) mixpanel.identify(id);
    },
    alias: (id: string) => {
        if (ENV_CHECK) mixpanel.alias(id);
    },
    track: (name: string, props?: any) => {
        if (ENV_CHECK) mixpanel.track(name, props);
    },
    people: {
        set: (props: any) => {
            if (ENV_CHECK) mixpanel.people.set(props);
        },
    },
};

export default actions;*/
