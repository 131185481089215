import {Button, Col, Row} from 'reactstrap';
import {useNavigate, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {useQuery} from "react-query";
import QRCode from "react-qr-code";
import Loader from "../../layouts/loader/Loader";
import ComponentCard from "../../components/ComponentCard";
import TagService from "../../services/tag.service"
import PrettyPrintJson from "../../components/PrettyPrintJson";
import {TagResponse} from "../../model/Tag";

const Page: React.FC = ({title, children}: any) => {
    const navigate = useNavigate();

    return (
        <>
            <ComponentCard
                title={title}
                extra={
                    <Button className="btn btn-success ms-auto float-end" size="sm" onClick={() => navigate(-1)}>
                        Go Back
                    </Button>
                }
            >
                {children}
            </ComponentCard>
        </>
    )
};


const TagItem = (props: any) => {
    const { edit, view } = props;
    const { id } = useParams()
    const navigate = useNavigate();
    const [notFound, setNotFound] = React.useState(false);
    const { isLoading, isError, data, error } = useQuery<TagResponse, Error>(['tag', id], () => TagService.findById(id), {cacheTime: 0, staleTime: 0, retry: false});

    useEffect(() => {
        if (isError && error) {
            setNotFound(true);
            return;
        }

        if (!view && !edit && !isLoading) {
            if (data?.order) {
                navigate(`/order/${data.order.id}`, { replace: true })
            }
        }
    }, [isLoading, view, edit]);



    if (isLoading) {
        return (
            <>
                <Loader />
            </>
        );
    }

    if (isError && !notFound) {
        return (
            <>
                <Page>
                    <span>An error has occurred: {error.message}</span>;
                </Page>
            </>
        );
    }

    const width = 256;
    const quitZone = 16;
    const maxWidth = (quitZone * 2) + width;

    if (view && data) {
        return (
            <>
                <ComponentCard
                    title={`${edit ? 'Edit' : ''} Tag ${data.tag.publicKey}`}
                    extra={
                        <Button className="btn btn-success ms-auto float-end" size="sm" onClick={() => navigate(-1)}>
                            Go Back
                        </Button>
                    }
                >
                    <Row>
                        <div style={{
                            background: 'white',
                            margin: '4px',
                            padding: `${quitZone}px`,
                            maxWidth: `${maxWidth}px`
                        }}>
                            <QRCode value={data.tag.publicKey} width={width}/>
                        </div>

                        <PrettyPrintJson {...data} />
                    </Row>
                </ComponentCard>
            </>
        );
    }

    return (
        <Row className="justify-content-center align-items-center full-height">
            <Col lg="6">
                <ComponentCard
                    extra={
                        <Button className="btn btn-success ms-auto float-end" size="sm" onClick={() => navigate(-1)}>
                            Go Back
                        </Button>
                    }
                >
                    <Row>
                        <h3>No data found for this tag</h3>
                        <Row>
                            <Col md="12" className="text-center p-2">
                                <Button
                                    className="w-200px"
                                    color="primary"
                                    onClick={() => navigate(-1)}
                                >Go back
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                </ComponentCard>
            </Col>
        </Row>
    );
};

TagItem.propTypes = {
    edit: PropTypes.bool,
    view: PropTypes.bool,
};

export default TagItem;

