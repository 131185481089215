import React from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Row} from 'reactstrap';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from "react-redux";
import {Link, Navigate, useNavigate} from 'react-router-dom';
import AuthLogo from "../../layouts/logo/AuthLogo";
import {ReactComponent as LeftBg} from '../../assets/images/bg/login-bgleft.svg';
import {ReactComponent as RightBg} from '../../assets/images/bg/login-bg-right.svg';
import {login} from "../../actions/auth.actions";
import {AppDispatch, RootState} from "../../store/Store";
import PTextInput from "../../components/form/PTextInput";
import ErrorMessage from "../../components/utility/ErrorMessage";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

    if (isLoggedIn) {
        return <Navigate to="/" />;
    }

    return (
        <div className="loginBox">
            <LeftBg className="position-absolute left bottom-0" />
            <RightBg className="position-absolute end-0 top" />
            <Container fluid className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col lg="12" className="loginContainer">
                        <AuthLogo />
                        <Card>
                            <CardBody className="p-4 m-1">
                                <ErrorMessage />
                                <h5 className="mb-0">Login</h5>
                                <small className="pb-4 d-block">
                                    Do not have an account? <Link to="/register">Sign Up</Link>
                                </small>
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    validationSchema={Yup.object({
                                        email: Yup.string()
                                            .email('Email is invalid')
                                            .required('Email is required'),
                                        password: Yup.string()
                                            .min(0, 'Password must be at least 6 characters')
                                            .required('Password is required'),
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        dispatch(login(values.email, values.password))
                                            .then(() => {
                                                navigate('/');
                                            })
                                            .catch(() => {
                                                setSubmitting(false)
                                            });
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <PTextInput
                                                label="Email"
                                                name="email"
                                                type="email"
                                                placeholder="jane@paperlessproduction.nl"
                                            />
                                            <PTextInput
                                                label="Password"
                                                name="password"
                                                type="password"
                                            />
                                            <FormGroup className="form-check d-flex" inline>
                                                <Link className="ms-auto text-decoration-none" to="/recover-password">
                                                    <small>Forgot Password?</small>
                                                </Link>
                                            </FormGroup>
                                            <FormGroup>
                                                <Button disabled={isSubmitting} type="submit" color="primary" className="me-2">
                                                    Login
                                                </Button>
                                            </FormGroup>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
