import {lazy} from "react";
import {Navigate} from "react-router-dom";
import Loadable from "../layouts/loader/Loadable";
import TagView from "../views/tag/TagView";
import TagItem from "../views/tag/TagItem";
import Register from "../views/auth/Register";
import Login from "../views/auth/Login";
import Maintanance from "../views/auth/Maintanance";
import RecoverPassword from "../views/auth/RecoverPassword";
import ArticleOverview from "../views/support/article/ArticleOverview";
import OptionsItem from "../views/superadmin/options/OptionsItem";

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")));
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")));
/***** Pages ****/
const RecoverAccount = Loadable(lazy(() => import("../views/auth/RecoverAccount")));
const OrderOverview = Loadable(lazy(() => import("../views/order/OrderOverview")));
const OrderItem = Loadable(lazy(() => import("../views/order/OrderItem")));
const CustomerOverview = Loadable(lazy(() => import("../views/admin/customer/CustomerOverview")));
const UserOverview = Loadable(lazy(() => import("../views/admin/user/UserOverview")));
const CompanyView = Loadable(lazy(() => import("../views/superadmin/company/CompanyView")));
const CustomerItem = Loadable(lazy(() => import("../views/admin/customer/CustomerItem")));
const Profile = Loadable(lazy(() => import("../views/auth/Profile")));
const CompanyItem = Loadable(lazy(() => import("../views/superadmin/company/CompanyItem")));
const OptionsView = Loadable(lazy(() => import("../views/superadmin/options/OptionsView")));
const UserItem = Loadable(lazy(() => import("../views/admin/user/UserItem")));
const Home = Loadable(lazy(() => import("../views/Home")));
const CompanySettings = Loadable(lazy(() => import("../views/auth/CompanySettings")));
const ArticleView = Loadable(lazy(() => import("../views/support/article/ArticleView")));
const ScanOverview = Loadable(lazy(() => import("../views/scan/ScanOverview")));
const TagRemoveOverview = Loadable(lazy(() => import("../views/tagremove/TagRemoveOverview")));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import("../views/auth/Error")));
/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/home" /> },
      { path: "/home", exact: true, element: <Home /> },
      { path: "/home/basic", exact: true, element: <Home basic/> },
      {
        path: "/order",
        element: <BlankLayout />,
        children: [
          { path: ":id/assign/:tag", element: <OrderItem assign />},
          { path: ":id/*", exact: true, element: <OrderItem /> },
          { path: ":id/edit", exact: true, element: <OrderItem edit /> },
          { path: ":id/basic", exact: true, element: <OrderItem basic /> },
          { path: "", element: <OrderOverview />},
        ]
      },
      {
        path: "/support",
        element: <BlankLayout />,
        children: [
          { path: "/support/article", exact: true, element: <ArticleOverview /> },
          {
            path: "/support/article",
            element: <BlankLayout />,
            children: [
              { path: ":id", exact: true, element: <ArticleView /> },
              { path: ":id/edit", exact: true, element: <ArticleView edit/> },
            ]
          },
        ]
      },
      { path: "/profile", exact: true, element: <Profile /> },
      { path: "/scan", exact: true, element: <ScanOverview /> },
      {
        path: "/tagremove",
        element: <BlankLayout/>,
        children: [
          {path: ":tag", element: <TagRemoveOverview assign/>},
          {path: "", element: <TagRemoveOverview/>},
        ]
      },
      {
        path: "/customer",
        element: <BlankLayout />,
        children: [
          { path: ":id", exact: true, element: <CustomerItem /> },
          { path: ":id/edit", exact: true, element: <CustomerItem edit /> },
          { path: "", element: <CustomerOverview />}
        ]
      },
      {
        path: "/user",
        element: <BlankLayout />,
        children: [
          { path: ":id", exact: true, element: <UserItem /> },
          { path: ":id/edit", exact: true, element: <UserItem edit /> },
          { path: "", element: <UserOverview />}
        ]
      },
      {
        path: "/tag",
        element: <BlankLayout />,
        children: [
          { path: ":id", exact: true, element: <TagItem /> },
          { path: ":id/view", exact: true, element: <TagItem view /> },
          { path: ":id/edit", exact: true, element: <TagItem edit /> },
          { path: "", element: <TagView />}
        ]
      },
      {
        path: "/tag",
        element: <BlankLayout />,
        children: [
          { path: ":id", exact: true, element: <TagItem /> },
        ]
      },
      { path: "/company-settings", exact: true, element: <CompanySettings /> },
      {
        path: "/superadmin",
        element: <BlankLayout />,
        children: [
          {
            path: "/superadmin/company",
            element: <BlankLayout />,
            children: [
              { path: ":id", exact: true, element: <CompanyItem /> },
              { path: ":id/edit", exact: true, element: <CompanyItem edit /> },
              { path: "", element: <CompanyView />}
            ]
          },
          {
            path: "/superadmin/tag",
            element: <BlankLayout />,
            children: [
              { path: ":id", exact: true, element: <TagItem /> },
              { path: ":id/view", exact: true, element: <TagItem view /> },
              { path: ":id/edit", exact: true, element: <TagItem edit /> },
              { path: "", element: <TagView />}
            ]
          },
          {
            path: "/superadmin/options",
            element: <BlankLayout />,
            children: [
              { path: ":id", exact: true, element: <OptionsItem /> },
              { path: "", element: <OptionsView />}
            ]
          },
        ]
      },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: '*', element: <Navigate to="/404" /> },

      {
        path: "/register",
        element: <BlankLayout />,
        children: [
          { path: "/register/invite/:token", exact: true, element: <Register /> },
          { path: '', element: <Register/> },
        ]
      },
      { path: 'login', element: <Login /> },
      { path: 'recover-password', element: <RecoverPassword /> },
      {
        path: "/recover-account",
        element: <BlankLayout />,
        children: [
          { path: ":token", exact: true, element: <RecoverAccount /> },
          { path: '', element: <Navigate to="/" /> },
        ]
      },
      { path: 'maintanance', element: <Maintanance /> },
      { path: '404', element: <Error /> },
    ],
  },
];

export default ThemeRoutes;
