import {Field, useField} from "formik";
import {FormGroup, Label} from "reactstrap";
import React from "react";

const PTextInput = ({ label, ...props }: any) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)

    const [field, meta] = useField(props);

    return (
        <FormGroup>
            {label && <Label htmlFor={props.id || props.name}>{label}</Label>}
            <Field
                className={`form-control${
                    meta.touched && meta.error ? ' is-invalid' : ''
                }`}
                {...field}
                {...props}
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </FormGroup>
    );
};


export default PTextInput;