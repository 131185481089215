import React, {useState} from "react";
import {Button, Modal, ModalHeader, Row, Table} from 'reactstrap';
import {Link} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import Loader from "../../layouts/loader/Loader";
import ComponentCard from "../../components/ComponentCard";
import TagService from "../../services/tag.service";
import {Tag} from "../../model/Tag";
import TagAdd from "./TagAdd";
import {deleteTag} from "../../actions/tag.actions";
import useConfirm from "../../hooks/useConfirm";

const TagView = () => {
    const queryClient = useQueryClient()
    const { confirm } = useConfirm();

    const { isLoading, isError, data, error } = useQuery<Tag[], Error>('tags', TagService.findAll)
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    };

    if (isLoading || !data) {
        return <Loader />
    }

    if (isError) {
        return <span>An error has occurred: {error.message}</span>;
    }

    const handleNewTag = (e: any) => {
        e.preventDefault();
        setModal(true);
    };

    const confirmDelete = async (tag: Tag) => {
        const isConfirmed = await confirm(`Are you sure you want to delete ${tag.publicKey}`);

        if (isConfirmed) {
            await deleteTag(tag, queryClient)
        }
    }

    return (
        <>
            <ComponentCard
                title="Tags"
                extra={
                    <Button className="btn btn-success ms-auto float-end" size="sm" onClick={handleNewTag}>
                        Add New Tag
                    </Button>
                }
            >
                <Row>
                    <Table className="no-wrap lign-middle" responsive borderless hover>
                        <thead>
                        <tr>
                            <th>Tag</th>
                            <th>Status</th>
                            <th className="w-25">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!data.length &&
                        <tr key="0" className="border-top">
                            <td>
                                <div className="d-flex align-items-center p-2">
                                    <div>
                                        <span>No tags found...</span>
                                    </div>
                                </div>
                            </td>
                        </tr>}
                        {data && data.map((tag: Tag) => (
                            <tr key={tag.publicKey} className="border-top">
                                <td>
                                    <div className="d-flex align-items-center p-2">
                                        <div>
                                            <h5 className="mb-0">{tag.publicKey}</h5>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span className="p-2 bg-success rounded-circle d-inline-block ms-3" />
                                </td>
                                <td>
                                    <div className="button-group">
                                        <Link to={`${tag.publicKey}/view`} >
                                            <Button className="btn" color="primary">
                                                View
                                            </Button>
                                        </Link>
                                        <Link to={`${tag.publicKey}`} >
                                            <Button className="btn" color="secondary">
                                                Tag Scan
                                            </Button>
                                        </Link>
                                        <Button className="btn" color="danger" onClick={() => confirmDelete(tag)}>
                                            Delete
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Row>
            </ComponentCard>

            {/***********Tag Add Box**************/}
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Add Tag</ModalHeader>
                <TagAdd click={toggle}/>
            </Modal>
            {/***********Tag Add Box End**************/}
        </>
    );
};

export default TagView;
