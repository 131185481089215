import axios from "axios";
import BASE_URL from "./constants";
import {Tag, TagResponse} from "../model/Tag";

const API_URL = `${BASE_URL}tag/`;

const findAll = async () => {
    const response = await axios.get<Tag[]>(`${API_URL}`);
    return response.data;
}

const findById = async (id: any) => {
    const response = await axios.get<TagResponse>(`${API_URL}${id}`);
    return response.data;
}

const create = async ({ publicKey }: Tag) => {
    const response = await axios.post<any>(`${API_URL}`, { publicKey });
    return response.data;
}

const deleteById = async (id: any) => {
    const response = await axios.delete<any>(`${API_URL}${id}`);
    return response.data;
}

const removeById = async (id: any) => {
    const response = await axios.post<any>(`${API_URL}${id}/remove`);
    return response.data;
}

const strip = (tag: string) => {
    tag = tag.replace(/[^a-zA-Z0-9;:-]/g, '');
    return tag;
}

const TagService = {
    findAll,
    findById,
    create,
    deleteById,
    removeById,
    strip
}
export default TagService;