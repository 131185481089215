import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {RootState} from "../../store/Store";

const LightTheme = React.lazy(() => import('./LightTheme'));
const DarkTheme = React.lazy(() => import('./DarkTheme'));

const ThemeSelector = ({
  children
}: any) => {
  const isDarkMode = useSelector((state: RootState) => state.auth.dark);
  return (
    <>
      <>{isDarkMode ? <DarkTheme /> : <LightTheme />}</>
      {children}
    </>
  );
};

ThemeSelector.propTypes = {
  children: PropTypes.node,
};

export default ThemeSelector;
