import AuthService from "../services/auth.service";
import {LoginSuccess, UpdateScanState, UserFetchingFail} from "../store/auth/AuthSlice";
import defineRulesFor from "../config/ability";
import ProfileService from "../services/profile.service";
import {ScanState} from "../model/ScanState";

export const me = (ability: any) => (dispatch: any) => {
    return AuthService.me().then(
        (response) => {
            dispatch(LoginSuccess({user: response}));
            if (ability) {
                ability.update(defineRulesFor(response.roles))
            }
            return Promise.resolve();
        },
        (error) => {
            dispatch(UserFetchingFail());
            return Promise.reject();
        }
    );
}
export const changeScanState = (scanState: ScanState) => (dispatch: any) => {
    dispatch(UpdateScanState({scanState}));
    return ProfileService.updatePreferences({scanState}).then(
        (response) => {
            return Promise.resolve();
        },
        (error) => {
            return Promise.reject();
        }
    );
}


export const changeDarkMode = (currentDark: boolean, dark: boolean) => (dispatch: any) => {
    if (currentDark === dark) {
        return Promise.resolve();
    }
    return ProfileService.update({dark}).then(
        (response) => {
            if (currentDark !== dark) {
                window.location.reload();
            }
            return Promise.resolve();
        },
        (error) => {
            return Promise.reject();
        }
    );
}