import {toast} from "react-hot-toast";
import {Toast, ToastBody, ToastHeader} from "reactstrap";
import * as Icon from "react-feather";

const success = async (msg: string) => {
    toast.custom((t) => {
        if (!t.visible) {
            return <></>;
        }
        return (
            <Toast>
                <ToastHeader icon={<Icon.CheckCircle color='#61d345'/>} toggle={() => toast.dismiss(t.id)}>
                    Success
                </ToastHeader>
                <ToastBody>
                    {msg}
                </ToastBody>
            </Toast>
        )
    });
}

const error = async (msg: string) => {
    toast.custom((t) => {
        if (!t.visible) {
            return <></>;
        }
        return (
            <Toast>
                <ToastHeader icon={<Icon.CheckCircle color='#dc3545'/>} toggle={() => toast.dismiss(t.id)}>
                    Error
                </ToastHeader>
                <ToastBody>
                    {msg}
                </ToastBody>
            </Toast>
        )
    });
}

const ToasterService = {
    success,
    error
}
export default ToasterService;