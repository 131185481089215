import {useDispatch, useSelector} from "react-redux";
import {HideDialog, ShowDialog} from "../store/confirmdialog/ConfirmdialogSlice";

let resolveCallback: any;
function useConfirm() {
    const dispatch = useDispatch();

    const closeConfirm = () => {
        dispatch(HideDialog())
    }

    const onConfirm = () => {
        closeConfirm();
        resolveCallback(true);
    };

    const onCancel = () => {
        closeConfirm();
        resolveCallback(false);
    };

    const confirm = (text: any) => {
        dispatch(ShowDialog(text));
        return new Promise((res, rej) => {
            resolveCallback = res;
        });
    };

    return { confirm, onConfirm, onCancel };
}

export default useConfirm;