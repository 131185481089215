import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    show: false,
    title: '',
};

export const ConfirmdialogSlice = createSlice({
    name: "confirmdialog",
    initialState,
    reducers: {
        ToggleDialog: (state) => {
            state.show = !state.show;
        },
        ShowDialog: (state, action) => {
            state.show = true;
            state.title = action.payload;
        },
        HideDialog: (state) => {
            state.show = false;
        },
    },
});

export const {
    ToggleDialog,
    ShowDialog,
    HideDialog,
} = ConfirmdialogSlice.actions;

export default ConfirmdialogSlice.reducer;
