import React from "react";
import "./loader.scss";
import { Spinner } from "reactstrap";

interface LoaderProps {
    fallback?: boolean
}

const defaultProps: LoaderProps = {
    fallback: true,
}

const Loader: React.FunctionComponent<LoaderProps> = (props) => (
  <div className={props.fallback ? "fallback-spinner" : "p-4"}>
    <div className="loading">
      <Spinner color="primary" />
    </div>
  </div>
);
Loader.defaultProps = defaultProps;

export default Loader;
