import React, {useState} from 'react';
import {Button, Card, CardBody, Col, Container, FormGroup, Row,} from 'reactstrap';
import {Link, useNavigate} from 'react-router-dom';
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import {useDispatch} from "react-redux";
import AuthLogo from '../../layouts/logo/AuthLogo';
import {ReactComponent as LeftBg} from '../../assets/images/bg/login-bgleft.svg';
import {ReactComponent as RightBg} from '../../assets/images/bg/login-bg-right.svg';
import {AppDispatch} from "../../store/Store";
import {recover} from "../../actions/auth.actions";
import PTextInput from "../../components/form/PTextInput";
import ErrorMessage from "../../components/utility/ErrorMessage";

const RecoverPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [goBack, setGoBack] = useState(false);

    const handleGoBack = () => {
        navigate("/login")
    }

    return (
        <div className="loginBox">
            <LeftBg className="position-absolute left bottom-0" />
            <RightBg className="position-absolute end-0 top" />
            <Container fluid className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col lg="12" className="loginContainer">
                        <AuthLogo />
                        <Card>
                            <CardBody className="p-4 m-1">
                                <ErrorMessage />
                                <h5 className="mb-0">Recover Password</h5>
                                <small className="pb-4 d-block">
                                    Already have an account? <Link to="/login">Login</Link>
                                </small>


                                <Formik
                                    initialValues={{
                                        email: '',
                                    }}
                                    validationSchema={Yup.object({
                                        email: Yup.string()
                                            .email('Email is invalid')
                                            .required('Email is required')
                                    })}
                                    onSubmit={(values, { setSubmitting }) => {
                                        dispatch(recover(values.email))
                                            .then(() => {
                                                setGoBack(true);
                                            })
                                            .finally(() => {
                                                setSubmitting(false)
                                            })
                                    }}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <PTextInput
                                                label="Email"
                                                name="email"
                                                type="email"
                                            />
                                            <FormGroup>
                                                {!goBack && <Button disabled={isSubmitting} type="submit" color="primary" className="me-2">
                                                    Reset
                                                </Button>}
                                                {goBack && <Button type="submit" color="info" className="me-2" onClick={handleGoBack}>
                                                    Go Back To Login
                                                </Button>}
                                            </FormGroup>
                                        </Form>
                                    )}
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RecoverPassword;
