import React from 'react';
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row,} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {ToggleDialog} from "../store/confirmdialog/ConfirmdialogSlice";
import useConfirm from "../hooks/useConfirm";
import {RootState} from "../store/Store";

const ConfirmDialog = () => {
    const dispatch = useDispatch();
    const { onConfirm, onCancel } = useConfirm();
    const show = useSelector((state: RootState) => state.confirmdialog.show);
    const title = useSelector((state: RootState) => state.confirmdialog.title);

    const toggle = () => {
        dispatch(ToggleDialog());
    };

    return (
        <Modal isOpen={show} toggle={toggle} size="md" onClosed={onCancel}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <h4>{title}</h4>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    type="submit"
                    onClick={toggle}
                >
                    Close
                </Button>
                <Button
                    color="danger"
                    type="submit"
                    onClick={onConfirm}
                >
                    Confirm
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ConfirmDialog;
