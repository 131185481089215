import AuthService from "../services/auth.service";
import {FetchUser, LoginFail, LoginSuccess, Logout, RegisterFail, RegisterSuccess} from "../store/auth/AuthSlice";
import {AddMessage, ClearMessage} from "../store/message/MessageSlice";
import Ana from "../analytis/Ana";
import ToasterService from "../services/toaster.service";

export const login = (email: any, password: any) => (dispatch: any) => {
    return AuthService.login(email, password).then(
        (data) => {
            const {user} = data;
            dispatch(LoginSuccess({user}));
            dispatch(FetchUser())

            Ana.identify(`${user.id}`, {
                $name: user.fullName,
                $email: user.email,
                $companyName: user.companyName,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch(ClearMessage())
            dispatch(LoginFail());
            dispatch(AddMessage({ message, type: "error" }));
            return Promise.reject();
        }
    );
};

export const register = (companyName: string, firstName: string, lastName: string, email: string, password: string) => (dispatch: any) => {
    return AuthService.register(companyName, firstName, lastName, email, password).then(
        (response) => {
            dispatch(RegisterSuccess());
            dispatch(ClearMessage())
            dispatch(AddMessage({message: response.data.message, type: "success", displayLater: true}));

            Ana.track('register');

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch(ClearMessage())
            dispatch(RegisterFail());
            dispatch(AddMessage( {message, type: "error"}));
            return Promise.reject();
        }
    );
};



export const handleInvite = (token: string, firstName: string, lastName: string, password: string) => (dispatch: any) => {
    return AuthService.handleInviteToken({token, firstName, lastName, password}).then(
        (response) => {
            dispatch(RegisterSuccess());
            dispatch(AddMessage({message: response.data.message, type: "success", displayLater: true}));

            // TODO: Invited??
            Ana.track('register');

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch(RegisterFail());
            dispatch(AddMessage( {message, type: "error"}));
            return Promise.reject();
        }
    );
};


export const logout = () => (dispatch: any) => {
    AuthService.logout().finally(() => {
        localStorage.removeItem("user");
        dispatch(Logout());
        Ana.reset();
    });
};


export const recover = (email: any) => (dispatch: any) => {
    return AuthService.recover(email).then(
        (data) => {
            const message = "If you have an active account, you will receive a password recovery mail shortly";
            dispatch(AddMessage({ message, type: "success" }));

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch(ClearMessage())
            dispatch(LoginFail());
            dispatch(AddMessage({ message, type: "error" }));
            return Promise.reject();
        }
    );
};

export const handleRecover = (token: string, password: string) => (dispatch: any) => {
    return AuthService.handleRecoverByToken(token, password).then(
        (data) => {
            ToasterService.success("Successfully recovered account. Please login using your new credentials.")
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch(AddMessage({ message, type: "error" }));
            return Promise.reject();
        }
    );
};