import {Ability, AbilityBuilder, AbilityClass} from '@casl/ability';

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
export type Subjects = 'all' | 'role_super_admin' | 'role_admin' | 'role_moderator' | 'role_user' | 'role_super_admin_exact' | 'role_admin_exact' | 'role_moderator_exact' | 'role_user_exact' ;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbilityClass = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(roles: string[]) {
  const { can, rules } = new AbilityBuilder(AppAbilityClass);

  roles.forEach(role => {
    if (role === 'ROLE_SUPER_ADMIN') {
      can('manage', 'all');
      can('manage', 'role_super_admin_exact');
      can('manage', 'role_super_admin');
      can('manage', 'role_admin');
      can('manage', 'role_moderator');
      can('manage', 'role_user');
    } else if (role === 'ROLE_ADMIN') {
      can('manage', 'role_admin_exact');
      can('manage', 'role_admin');
      can('manage', 'role_moderator');
      can('manage', 'role_user');
    } else if (role === 'ROLE_MODERATOR') {
      can('manage', 'role_moderator_exact');
      can('manage', 'role_moderator');
      can('manage', 'role_user');
    } else {
      can('manage', 'role_user');
      can('manage', 'role_user_exact');
    }
  })
  return rules;
}

export function buildAbilityFor(roles: string[]): AppAbility {
  return new AppAbilityClass(defineRulesFor(roles));
}
