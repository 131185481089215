import axios from "axios";
import BASE_URL from "./constants";

const API_URL = `${BASE_URL}profile/`;

const update = async (data: any) => {
    const response = await axios.put<any>(`${API_URL}`, data);
    return response.data;
}

const updatePreferences = async (data: any) => {
    const response = await axios.put<any>(`${API_URL}preferences`, data);
    return response.data;
}


const ProfileService = {
    update,
    updatePreferences
}
export default ProfileService;