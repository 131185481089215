import {createSlice} from "@reduxjs/toolkit";
import {toast} from "react-hot-toast";
import {ScanState} from "../../model/ScanState";

export interface AuthState {
    isLoggedIn: boolean
    isFetchingInitState: boolean
    isMobileSidebar: boolean
    dark: boolean
    scanState: ScanState
    user?: CurrentUser | null
}

export interface CurrentUser {
    id: number
    fullName: string
    firstName: string
    lastName: string
    companyName: string
    email: string
    roles: string[]
}
export const initialState: AuthState = {
    isLoggedIn: false,
    isFetchingInitState: false,
    isMobileSidebar: false,
    dark: false,
    scanState: "NFC"
}

export const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        RegisterSuccess: (state) => {
            state.isLoggedIn = false;
        },
        RegisterFail: (state) => {
            state.isLoggedIn = false;
        },
        FetchUser: (state) => {
            state.isFetchingInitState = true;
        },
        LoginSuccess: (state, action) => {
            state.user = action.payload.user;
            state.dark = action.payload.user.dark;
            state.scanState = action.payload.user.scanState;
            state.isLoggedIn = true;
            state.isFetchingInitState = false;
        },
        UpdateScanState: (state, action) => {
            state.scanState = action.payload.scanState;
        },
        UserFetchingFail: (state) => {
            state.isLoggedIn = false;
            state.isFetchingInitState = false;
        },
        LoginFail: (state) => {
            state.isLoggedIn = false;
            state.isFetchingInitState = false;
            state.user = null;
        },
        Logout: (state) => {
            if (state.isLoggedIn) {
                localStorage.removeItem("user");
                toast.error(`You have been logged out!`)
            }
            state.isLoggedIn = false;
            state.user = null;
        },
        ToggleMobileSidebar: (state) => {
            state.isMobileSidebar = !state.isMobileSidebar;
        },
    },
});



export const {
    RegisterSuccess,
    RegisterFail,
    LoginSuccess,
    LoginFail,
    Logout,
    UpdateScanState,
    UserFetchingFail,
    FetchUser,
    ToggleMobileSidebar
} = AuthSlice.actions;

export default AuthSlice.reducer;
