import {SupportArticle} from "../model/SupportArticle";
import SupportArticleService from "../services/supportarticle.service";
import ToasterService from "../services/toaster.service";

export const addArticle = (article: SupportArticle, queryClient: any) => {
    return SupportArticleService.create(article).then(
        (response) => {
            queryClient.invalidateQueries(['supportarticle'])
            ToasterService.success(`Added Article: ${article.title}`)
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            ToasterService.error(message);
            return Promise.reject();
        }
    );
}

export const updateArticle = (id: number | undefined, article: SupportArticle, queryClient: any) => {
    return SupportArticleService.update(id, article).then(
        (response) => {
            queryClient.invalidateQueries(['supportarticle'])
            ToasterService.success(`Updated Article: ${article.title}`)
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            ToasterService.error(message);
            return Promise.reject();
        }
    );
}


export const deleteArticle = (id: number | undefined, queryClient: any) => {
    return SupportArticleService.deleteById(id).then(
        (article) => {
            queryClient.invalidateQueries(['supportarticle'])
            ToasterService.success(`Deleted Article: ${article.title}`)
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            ToasterService.error(message);
            return Promise.reject();
        }
    );
}