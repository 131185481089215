import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import axios from "axios";
import * as Sentry from '@sentry/browser';
import {QueryClient, QueryClientProvider} from "react-query";
import {Toaster} from "react-hot-toast";
import ConfirmDialog from "./components/ConfirmDialog";
import {store} from './store/Store';
import App from './App';
import AuthService from "./services/auth.service";
import {AbilityContext} from './ability/Can';
import {buildAbilityFor} from './config/ability';
import {logout} from "./actions/auth.actions";
import ErrorFallback from "./components/utility/ErrorFallback";


const ability = buildAbilityFor(['ROLE_USER']);
const {dispatch} = store;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false
        }
    }
});

// Add a request interceptor
axios.interceptors.request.use(config => {
    // Do something before request is sent
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    if (user && user.token && config.headers) {
        config.headers.Authorization = `Bearer ${user.token}`;
    }
    return config;
}, error => {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalConfig = error.config;
        if (error.response) {
            if (error.response.status === 401 && !originalConfig.retry) {
                originalConfig.retry = true;
                try {
                    await AuthService.refreshToken();
                    return axios(originalConfig);
                } catch (err: any) {
                    if (err.response.status === 403) {
                        dispatch(logout());
                    }
                }
            }
        }
        return Promise.reject(error);
    }
);

Sentry.init({
    dsn: 'https://6422da5828e14d7aa1d2d7880456a34d@o1260947.ingest.sentry.io/6437916',
});

ReactDOM.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <AbilityContext.Provider value={ability}>
                <Toaster
                    toastOptions={{duration: 5_000}}
                    position="top-right"
                    reverseOrder={false}
                />
                <ConfirmDialog />

                <BrowserRouter>
                    <ErrorFallback>
                        <App ability={ability} />
                    </ErrorFallback>
                </BrowserRouter>
            </AbilityContext.Provider>
        </QueryClientProvider>
    </Provider>,

    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
