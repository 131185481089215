import {Field, useField} from "formik";
import React from "react";
import {FormGroup, Label} from "reactstrap";

const PCheckbox = ({ children, ...props }: any) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input>. We can use field meta to show an error
    // message if the field is invalid and it has been touched (i.e. visited)

    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <FormGroup inline className="form-check">
            <Label className="checkbox-input">
                <Field
                    className={`form-check-input ${
                        meta.touched && meta.error ? ' is-invalid' : ''
                    }`}
                    type="checkbox"
                    {...field}
                    {...props}
                />
                {children}
            </Label>
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </FormGroup>
    );
};

export default PCheckbox;