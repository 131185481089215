import axios from "axios";
import BASE_URL from "./constants";
import {SupportArticle} from "../model/SupportArticle";
import {PagedResponse} from "../model/PagedResponse";

const API_URL = `${BASE_URL}supportarticle/`;

const findAll = async (data: any) => {
    const response = await axios.post<PagedResponse<SupportArticle>>(`${API_URL}all`, data);
    return response.data;
}

const findById = async (id: any) => {
    const response = await axios.get<SupportArticle>(`${API_URL}${id}`);
    return response.data;
}

const create = async (article: SupportArticle) => {
    const response = await axios.post<SupportArticle>(`${API_URL}`, article);
    return response.data;
}

const update = async (id: number | undefined, article: SupportArticle) => {
    const response = await axios.put<SupportArticle>(`${API_URL}${id}`, article);
    return response.data;
}

const deleteById = async (id: any) => {
    const response = await axios.delete<SupportArticle>(`${API_URL}${id}`);
    return response.data;
}

const SupportArticleService = {
    findAll,
    findById,
    create,
    update,
    deleteById,
}
export default SupportArticleService;
