import React from 'react';
import {Input, Pagination as Pag, PaginationItem, PaginationLink} from "reactstrap";
import {DOTS, usePagination} from './usePagination';

const Pagination = (props: any) => {
    const {
        onPageChange,
        onItemsChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        onItemsChange
    });

    if (paginationRange == null) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const onSizeChange = (size: number) => {
        onItemsChange(size);
    }

    const lastPage = paginationRange[paginationRange.length - 1];
    return (
        <Pag aria-label="Paged navigation">
            <PaginationItem disabled={currentPage === 1}>
                <PaginationLink previous onClick={onPrevious} />
            </PaginationItem>

            {paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <PaginationItem key={pageNumber + index} disabled>
                            <PaginationLink>&#8230;</PaginationLink>
                        </PaginationItem>
                    )
                }
                return (
                    <PaginationItem
                        key={pageNumber}
                        active={pageNumber === currentPage}
                    >
                        <PaginationLink onClick={() => onPageChange(pageNumber)}>{pageNumber}</PaginationLink>
                    </PaginationItem>
                );
            })}
            <PaginationItem disabled={currentPage === lastPage || lastPage == null}>
                <PaginationLink next onClick={onNext} />
            </PaginationItem>
            <div className="px-4 page-item">
                <Input value={pageSize} type="select" onChange={event => {
                    onSizeChange(Number(event.target.value));
                }}>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                </Input>
            </div>
        </Pag>
    );
};

export default Pagination;