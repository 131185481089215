import {Tag} from "../model/Tag";
import TagService from "../services/tag.service";
import ToasterService from "../services/toaster.service";

export const addTag = (tag: Tag, queryClient: any) => {
    return TagService.create(tag).then(
        (response) => {
            ToasterService.success(`Added Tag ${tag.publicKey}`)
            queryClient.invalidateQueries(['tags'])
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            ToasterService.error(message);
            return Promise.reject();
        }
    );
}

export const removeOrderFromTag = (tag: Tag) => {
    return TagService.removeById(tag.publicKey).then(
        (response) => {
            ToasterService.success(`Successfully removed order from the tag.`)
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            ToasterService.error(message);
            return Promise.reject();
        }
    );
}

export const deleteTag = (tag: Tag, queryClient: any) => {
    return TagService.deleteById(tag.publicKey).then(
        (response) => {
            ToasterService.success(`Deleted Tag ${tag.publicKey}`)
            queryClient.refetchQueries(['tags'])
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            ToasterService.error(message);
            return Promise.reject();
        }
    );
}