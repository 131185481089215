import React, {Suspense} from 'react';
import {useRoutes} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import {AppDispatch, RootState} from "./store/Store";
import {me} from "./actions/profile.actions";

const App = (props: any) => {
    const routing = useRoutes(Themeroutes);
    const isMode = useSelector((state: RootState) => state.auth.dark);
    const dispatch = useDispatch<AppDispatch>();

    const isFetchingInitState = useSelector((state: RootState) => state.auth.isFetchingInitState);
    if (isFetchingInitState) {
        dispatch(me(props.ability))
        return <Loader />
    }

    return (
        <Suspense fallback={<Loader />}>
            <div
                className={`ltr ${isMode ? 'dark' : ''}`}
                dir='ltr'
            >
                <ThemeSelector />
                {routing}
            </div>
        </Suspense>
    );
};

export default App;
