import axios from "axios";
import BASE_URL from "./constants";
import {Company} from "../model/Company";

const API_URL = `${BASE_URL}company/`;

const findAll = async () => {
    const response = await axios.get<Company[]>(`${API_URL}`);
    return response.data;
}

const findById = async (id: any) => {
    const response = await axios.get<Company>(`${API_URL}${id}`);
    return response.data;
}

const deleteById = async (id: any) => {
    const response = await axios.delete<any>(`${API_URL}${id}`);
    return response.data;
}

const CompanyService = {
    findAll,
    findById,
    deleteById,
}
export default CompanyService;