import {useField} from "formik";
import React, {useEffect, useState} from "react";
import {FormGroup, Label} from "reactstrap";
import Select from "react-select";
import {Roles} from "../../model/Role";

const PRoleDropdown = ({label, ...props}: any) => {
    const [option, setOption] = useState<any>([]);
    const [field, meta, helpers] = useField(props.name);

    const { setValue } = helpers;

    useEffect(() => {
        if (field.value) {
            if (Array.isArray(field.value)) {
                field.value.forEach((roleNames: any) => {
                    Roles.forEach(role => {
                        if (roleNames === role.value) {
                            setOption([role]);
                        }
                    })
                });
            } else {
                Roles.forEach(role => {
                    if (field.value === role.value) {
                        setOption([role]);
                    }
                })
            }
        }
    }, [field.value]);

    const options: any = []
    Roles.map((value: any) => {
        return (options.push({...value}));
    })

    console.log(meta)

    return (
        <FormGroup>
            <Label htmlFor={props.id || props.name}>{label}</Label>
            <Select
                {...field}
                {...props}
                className={`react-select-container${
                    meta.touched && meta.error ? ' is-invalid' : ''
                }`}
                classNamePrefix="react-select"
                options={options}
                isClearable
                value={option}
                onChange={(value: any) => {
                    setOption(value)
                    if (value) {
                        setValue([value.value])
                    } else {
                        setValue([])
                    }
                }}
            />
            {meta.touched && meta.error ? (
                <div className="invalid-feedback">{meta.error}</div>
            ) : null}
        </FormGroup>
    );
};

export default PRoleDropdown;
