import React from 'react';
import "./logo.scss";

const AuthLogo = () => {
  return (
    <div className="p-4 d-flex justify-content-center gap-2 ">
      <img className="logo" src="/logo.png"  alt="Logo"/>
    </div>
  );
};

export default AuthLogo;
