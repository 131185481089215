import React from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Form, FormGroup, Input, Label, ModalBody, ModalFooter, Row,} from 'reactstrap';
import {useDispatch} from "react-redux";
import {useQueryClient} from "react-query";
import {addTag} from "../../actions/tag.actions";

const TagAdd = ({ click }: any) => {
    const queryClient = useQueryClient()
    const dispatch = useDispatch();

    const [values, setValues] = React.useState({
        publicKey: ''
    });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        addTag({publicKey: values.publicKey}, queryClient);
    };

    return (
        <Form onSubmit={handleSubmit}>
            <ModalBody>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="firstName">Name</Label>
                            <Input
                                className="form-control"
                                type="text"
                                name="publicKey"
                                id="publicKey"
                                value={values.publicKey}
                                onChange={(e) => setValues({ ...values, publicKey: e.target.value })}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    type="submit"
                    onClick={click}
                    disabled={values.publicKey.length === 0}
                >
                    Add Tag
                </Button>
            </ModalFooter>
        </Form>
    );
};

TagAdd.propTypes = {
    click: PropTypes.any,
};

export default TagAdd;
