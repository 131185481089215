import React, {useState} from "react";
import {Button, Col, FormGroup, Input, Label, Modal, ModalHeader, Row, Spinner, Table} from 'reactstrap';
import {Link} from "react-router-dom";
import {useQuery, useQueryClient} from "react-query";
import ArticleService from "../../../services/supportarticle.service"
import Loader from "../../../layouts/loader/Loader";
import ComponentCard from "../../../components/ComponentCard";
import {SupportArticle} from "../../../model/SupportArticle";
import {PagedResponse} from "../../../model/PagedResponse";
import Pagination from "../../../components/Pagination";
import CanAccess from "../../../ability/CanAccess";
import ArticleAdd from "./ArticleAdd";
import {deleteArticle} from "../../../actions/article.actions";
import useConfirm from "../../../hooks/useConfirm";

const ArticleOverview = ({query}: any) => {
    const queryClient = useQueryClient()
    const { confirm } = useConfirm();
    const { isLoading, isFetching, isError, data, error } = query;

    if (isError) {
        return <span>An error has occurred: {error.message}</span>;
    }

    const confirmDelete = async (article: SupportArticle) => {
        const isConfirmed = await confirm(`Are you sure you want to delete ${article.title}`);

        if (isConfirmed) {
            await deleteArticle(article.id, queryClient)
        }
    }

    return (
        <>
            <Row>
                <Table className="no-wrap lign-middle" responsive borderless hover>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th className="w-25">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading || isFetching ? (
                        <tr key="0" className="border-top">
                            <td>
                                <Loader fallback={false} />
                            </td>
                        </tr>
                    ) : (<>
                            {!data.content.length &&
                                <tr key="0" className="border-top">
                                    <td>
                                        <div className="d-flex align-items-center p-2">
                                            <div>
                                                <span>No users found...</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>}

                            {data && data.content && data.content.map((article: SupportArticle) => (
                                <tr key={article.id} className="border-top">
                                    <td>
                                        {article.title}
                                    </td>
                                    <td>
                                        {article.description}
                                    </td>
                                    <td>
                                        <div className="button-group">
                                            <Link to={`${article.id}`} >
                                                <Button className="btn" color="primary">
                                                    Read more
                                                </Button>
                                            </Link>
                                            <CanAccess perm="role_super_admin">
                                                <Link to={`${article.id}/edit`} >
                                                    <Button className="btn" color="secondary">
                                                        Edit
                                                    </Button>
                                                </Link>
                                                    <Button className="btn" color="danger" onClick={() => confirmDelete(article)} >
                                                        Delete
                                                    </Button>
                                            </CanAccess>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </>
                    )}
                    </tbody>
                </Table>
            </Row>
        </>
    );
};


const ArticleView = () => {
    const [modal, setModal] = useState(false);
    const [page, setPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const toggle = () => {
        setModal(!modal);
    };

    const [filters, setFilters] = useState<any>({
        search: '',
    });

    const { isLoading, isFetching, isError, data, error, refetch } = useQuery<PagedResponse<SupportArticle>, Error>(['supportarticle', itemsPerPage, page], () => {
        return ArticleService.findAll({filters, page: page - 1, itemsPerPage})
    }, {
        keepPreviousData : true,
        onSuccess: (response) => {
            if (page > response.totalPages) {
                setPage(1);
            }
        }
    })

    const handleSearch = async () => {
        await refetch();
    }

    const handleEnter = async (e: any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await handleSearch();
        }
    }

    const handleNewArticle = (e: any) => {
        e.preventDefault();
        setModal(true);
    };

    return (
        <>
            <ComponentCard
                title="Articles"
                extra={
                    <CanAccess perm="role_super_admin">
                        <Button className="btn btn-success ms-auto float-end" size="sm" onClick={handleNewArticle}>
                            Add New Article
                        </Button>
                    </CanAccess>
                }
            >

                <FormGroup>
                    <Row>
                        <Col md="6">
                            <Row>
                                <Label sm="4"><h5>Search</h5></Label>
                                <Col sm="6">
                                    <Input type="text"
                                           onKeyDown={handleEnter}
                                           value={filters.search}
                                           onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Button disabled={isFetching} className="btn ms-auto float-end" color="primary" onClick={handleSearch}>
                        {isFetching ? <Spinner size="sm"/> : <i className="bi bi-send"/>} Search
                    </Button>
                </FormGroup>
            </ComponentCard>


            <ComponentCard>
                <Row className="align-items-center">
                    <Col md="4">
                        <span className="m-2">{data?.totalElements} results</span>
                    </Col>
                </Row>
                <hr />

                <ArticleOverview query={{isLoading, isFetching, isError, data, error}} />

                <Row>
                    <Pagination
                        currentPage={page}
                        totalCount={data?.totalElements}
                        pageSize={itemsPerPage}
                        onItemsChange={(newSize: number) => setItemsPerPage(newSize)}
                        onPageChange={(newPage: number) => setPage(newPage)}
                    />
                </Row>
            </ComponentCard>

            {/***********Article Add Box**************/}
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Add Article</ModalHeader>
                <ArticleAdd click={toggle}/>
            </Modal>
            {/***********Article Add Box End**************/}
        </>
    );
};

export default ArticleView;
