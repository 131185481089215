import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import MessageType from "./MessageType";
import ToasterService from "../../services/toaster.service";

export interface MessageState {
    messages: MessageType[];
}

const initialState: MessageState = {
    messages: []
};

export const MessageSlice = createSlice({
    name: "message",
    initialState,
    reducers: {
        AddMessage: (state, action: PayloadAction<MessageType>) => {
            const { type, message, displayLater } = action.payload;
            const { messages } = state;
            if (type === "success") {
                ToasterService.success(message)
            }
            if (type === "error") {
                ToasterService.error(message)
            }
            if (message) {
                messages.push({message, type, displayLater});
            }
        },
        ClearMessage: (state) => {
            const { messages } = state;
            const newArray: MessageType[] = [];

            messages.forEach((value) => {
                if (value.displayLater) {
                    value.displayLater = false;
                    newArray.push(value);
                }
            })

            state.messages = newArray;
        },
    },
});

export const {
    AddMessage,
    ClearMessage,
} = MessageSlice.actions;

export default MessageSlice.reducer;
