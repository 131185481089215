import React, {ReactElement, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {RootState} from "../../store/Store";
import {ClearMessage} from "../../store/message/MessageSlice";
import Message from "./Message";

const ErrorMessage: React.FC = (): ReactElement | null => {
    const location = useLocation();
    const dispatch = useDispatch();
    const messages = useSelector((state: RootState) => state.message.messages);

    useEffect(() => {
        dispatch(ClearMessage())
    }, [location]);

    if (!messages) {
        return null;
    }

    return (
        <div className="form-group">
            {messages.map((message, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return <Message key={index} {...message} />
            })}
        </div>
    );
}

export default ErrorMessage;