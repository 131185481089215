import {Card, CardBody, CardSubtitle, CardTitle} from 'reactstrap';
import PropTypes from 'prop-types';

const ComponentCard = ({
    children,
    title,
    subtitle,
    extra
}: any) => {
    return (
        <Card>
            {title && <CardTitle tag="h4" className="border-bottom px-4 py-3 mb-0">
                {title}{extra}
            </CardTitle>}
            <CardBody className="p-4">
                {subtitle && <CardSubtitle className="text-muted mb-3">{subtitle || ''}</CardSubtitle>}
                <div>{children}</div>
            </CardBody>
        </Card>
    );
};

ComponentCard.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    extra: PropTypes.node,
    subtitle: PropTypes.node
};

export default ComponentCard;
