import PropTypes from "prop-types";
import Can from "./Can";

const CanAccess = ({
                       children,
                       action = "read",
                       perm
                   }: any) => {
    if (perm) {
        return (
            <Can I={action} a={perm}>
                {children}
            </Can>
        );
    }
    return children;
};

CanAccess.propTypes = {
    children: PropTypes.node,
    action: PropTypes.string,
    perm: PropTypes.any,
};

export default CanAccess;
